import { normalize } from 'normalizr';
import { AnyAction } from 'redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { client } from '../api/client';
import { clustersEntity } from '../schemas';
import { Pagination, Cluster } from '../interfaces';
import { RootState } from '.';
import { paginate, safeArray } from '../utils';
import HYDRATE_FIX from './_HYDRATE_FIX';

export const createCluster = createAsyncThunk(
  'clusters/create',
  async cluster => {
    const response = await client.post('/clusters', {
      cluster
    });
    const normalized = normalize(response.data.data, clustersEntity);

    return normalized.entities;
  }
);

export const fetchCluster = createAsyncThunk(
  'clusters/single',
  async (id: string) => {
    const response = await client.get(`/clusters/${id}`);
    const normalized = normalize(response.data.data, clustersEntity);

    return normalized.entities;
  }
);

export const fetchClusterHasDates = createAsyncThunk(
  'clusters/has_dates',
  async (id: string) => {
    const response = await client.get(`/clusters/has_dates/${id}`);
    return response.data;
  }
);

export const fetchClusters = createAsyncThunk(
  'clusters/all',
  async (filter: Pagination) => {
    const response = await client.get(`/clusters`, filter);
    const normalized = normalize(response.data.data, [clustersEntity]);

    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const updateCluster = createAsyncThunk(
  'clusters/update',
  async ({ id, cluster }: { id: string; cluster: Cluster }) => {
    const response = await client.put(`/clusters/${id}`, {
      cluster
    });
    const normalized = normalize(response.data.data, clustersEntity);

    return normalized.entities;
  }
);

export const deleteCluster = createAsyncThunk(
  'clusters/delete',
  async (id: number) => {
    await client.delete(`/clusters/${id}`);
    return id;
  }
);
const clusterAdapter = createEntityAdapter<Cluster>();
const initialState = clusterAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const clusterSlice = createSlice({
  name: 'clusters',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      HYDRATE_FIX(payload, () => {
        clusterAdapter.upsertMany(state, safeArray(payload.clusters.entities));
        state.pagination = payload.clusters.pagination;
      });
    });
    builder.addCase(createCluster.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createCluster.fulfilled, (state, { payload }) => {
      clusterAdapter.upsertMany(state, safeArray(payload.clusters));
      state.isLoading = false;
    });
    builder.addCase(createCluster.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchClusters.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchClusters.fulfilled, (state, { payload }) => {
      clusterAdapter.upsertMany(state, safeArray(payload.clusters));
      state.isLoading = false;
      state.pagination = payload.pagination;
    });
    builder.addCase(fetchClusters.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchCluster.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchCluster.fulfilled, (state, { payload }) => {
      clusterAdapter.upsertMany(state, safeArray(payload.clusters));
      state.isLoading = false;
    });
    builder.addCase(fetchCluster.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(updateCluster.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateCluster.fulfilled, (state, { payload }) => {
      clusterAdapter.upsertMany(state, safeArray(payload.clusters));
      state.isLoading = false;
    });
    builder.addCase(updateCluster.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteCluster.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteCluster.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteCluster.rejected, state => {
      state.isLoading = false;
    });
  }
});

export const clusterSelectors = clusterAdapter.getSelectors<RootState>(
  state => state.clusters
);

export default clusterSlice.reducer;
