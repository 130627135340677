import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { Field } from 'react-final-form';

import Clickable from './Clickable';
import Icon from './Icon';
import classNames from '../utils/react/classNames';
import { compose, required } from '../utils';
import { FormHelperText } from '@mui/material';
import Label from './Label';

const useStyles = makeStyles(theme => ({
  checkbox: {
    '& input[type="checkbox"]': {
      display: 'none',
      flexShrink: 0
    },
    ...(theme.components?.checkbox?.root
      ? theme.components?.checkbox?.root
      : {})
  },
  label: {
    marginBottom: 0,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    textAlign: 'left',
    ...(theme.components?.checkbox?.label
      ? theme.components?.checkbox?.label
      : {})
  },
  box: {
    width: '1em',
    height: '1em',
    border: props => [
      [
        1,
        'solid',
        props.disabled
          ? theme.color.common.grey.light
          : theme.color.common.grey.main
      ]
    ],
    ...(theme.components?.checkbox?.box ? theme.components?.checkbox?.box : {})
  },
  checked: {
    background: props => {
      if (props.color === 'primary') {
        return props.disabled
          ? theme.color.primary.light
          : theme.color.primary.main;
      }

      return 'transparent';
    }
  },
  textError: {
    ...(theme.components?.checkbox?.error
      ? theme.components?.checkbox?.error
      : {})
  },
  textHelper: {
    '&:not(.Mui-error)': {
      paddingLeft: theme.spacing(3.8),
      color: theme.color.text.light,
      fontSize: theme.font.size?.small
    }
  }
}));

function CheckboxAdapter({ input, meta, ...rest }) {
  return (
    <Checkbox
      {...input}
      {...rest}
      checked={input.checked}
      onChange={value => input.onChange(value)}
      onBlur={() => meta.touched && input.onBlur()}
      error={!!(meta.touched && meta.error)}
      errorText={meta.touched ? meta.error : ''}
    />
  );
}

function CheckboxField(props) {
  function getValidators() {
    const validators = [];

    if (props.required) validators.push(required);

    return validators;
  }

  return (
    <Field
      name={props.id}
      {...props}
      component={CheckboxAdapter}
      validate={compose(getValidators())}
      validateFields={props.validateFields || []}
    />
  );
}

export default function Checkbox(props) {
  const [checked, setChecked] = useState(props.checked);
  const classes = useStyles(props);

  function handleCheckbox(e) {
    setChecked(!checked);
    setTimeout(() => props.onChange(!checked, e));
  }

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const inner = (
    <Box display="flex">
      <input
        id={props.id}
        type="checkbox"
        tabIndex={props.readOnly ? -1 : 0}
        readOnly={props.readOnly}
        checked={checked}
        onChange={handleCheckbox}
        disabled={props.disabled}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classNames([
          classes.box,
          props.checked ? classes.checked : null
        ])}
      >
        {checked && (
          <Icon
            name="check"
            disabled={props.disabled}
            color={props.color === 'primary' ? 'contrastPrimary' : props.color}
          />
        )}
      </Box>
      <Label
        className={classes.label}
        disabled={props.disabled}
        required={props.required}
      >
        {props.children}
      </Label>
    </Box>
  );

  return (
    <Box className={classes.checkbox} display="inline">
      {props.readOnly ? (
        inner
      ) : (
        <Clickable
          data-testid={props['data-testid']}
          disabled={props.disabled}
          onClick={e => handleCheckbox(e)}
        >
          {inner}
        </Clickable>
      )}
      {(props.errorText || props.helperText) && (
        <FormHelperText
          classes={{ root: classes.textHelper, error: classes.textError }}
          aria-label={props.errorText || props.helperText}
          error={props.error}
        >
          {props.errorText || props.helperText}
        </FormHelperText>
      )}
    </Box>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  ['data-testid']: PropTypes.string,
  children: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  color: PropTypes.oneOf(['default', 'primary', 'secondary'])
};

Checkbox.defaultProps = {
  children: '',
  checked: false,
  readOnly: false,
  onChange: () => {},
  disabled: false,
  color: 'default'
};

Checkbox.Field = CheckboxField;
