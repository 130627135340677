import { normalize } from 'normalizr';
import { AnyAction } from 'redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { client } from '../api/client';
import { datesEntity } from '../schemas';
import {
  createDateSeries,
  deleteDateSeries,
  fetchDateSeries,
  fetchSingleDateSeries,
  updateDateSeries,
  writeSingleDateSeries
} from './dateSeries.slice';
import { fetchBooking, fetchBookings } from './booking.slice';
import { CustomDate } from '../interfaces';
import { RootState } from '.';
import { fetchRequest, fetchRequests } from './request.slice';
import {
  createEvent,
  deleteEvent,
  fetchEvents,
  updateEvent
} from './event.slice';
import { safeArray } from '../utils';
import HYDRATE_FIX from './_HYDRATE_FIX';

export const fetchDates = createAsyncThunk('dates/all', async () => {
  const response = await client.get('/dates');
  const normalized = normalize(response.data.data, [datesEntity]);

  return normalized.entities;
});

export const updateDate = createAsyncThunk(
  'dates/update',
  async ({ id, date }: { id: string; date: CustomDate }) => {
    const response = await client.put(`/dates/${id}`, {
      date: date
    });
    const normalized = normalize(response.data.data, datesEntity);

    return normalized.entities;
  }
);

export const updateDateSkipped = createAsyncThunk(
  'dates/skipped',
  async (dates: { id: string; skipped: boolean }[]) => {
    const response = await client.put('/dates_skip', {
      dates
    });
    const normalized = normalize(response.data.data, datesEntity);

    return normalized.entities;
  }
);

export const updateDateCancelled = createAsyncThunk(
  'dates/cancelled',
  async (dates: { id: string; cancelled: boolean }[]) => {
    const response = await client.put('/dates_cancel', {
      dates
    });
    const normalized = normalize(response.data.data, datesEntity);

    return normalized.entities;
  }
);

export const conflictsDates = createAsyncThunk(
  'dates/conflicts',
  async ({ date, filter }: { date: CustomDate; filter: any }) => {
    const response = await client.post(
      '/conflicts',
      {
        date: date
      },
      {
        date_series: true,
        status: 'new,accepted',
        ...filter
      }
    );

    return response.data.data;
  }
);

const dateAdapter = createEntityAdapter<CustomDate>();
const initialState = dateAdapter.getInitialState({ isLoading: false });
const dateSlice = createSlice({
  name: 'dates',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      HYDRATE_FIX(payload, () => {
        dateAdapter.upsertMany(state, safeArray(payload.dates.entities));
      });
    });
    builder.addCase(fetchDates.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchDates.fulfilled, (state, { payload }) => {
      dateAdapter.upsertMany(state, safeArray(payload.dates));
      state.isLoading = false;
    });
    builder.addCase(fetchDates.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(updateDate.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateDate.fulfilled, (state, { payload }) => {
      dateAdapter.upsertMany(state, safeArray(payload.dates));
      state.isLoading = false;
    });
    builder.addCase(updateDate.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchDateSeries.fulfilled, (state, { payload }) => {
      dateAdapter.upsertMany(state, safeArray(payload.dates));
    });
    builder.addCase(fetchSingleDateSeries.fulfilled, (state, { payload }) => {
      dateAdapter.upsertMany(state, safeArray(payload.dates));
    });
    builder.addCase(writeSingleDateSeries.fulfilled, (state, { payload }) => {
      dateAdapter.upsertMany(state, safeArray(payload.dates));
      state.isLoading = false;
    });
    builder.addCase(fetchBooking.fulfilled, (state, { payload }) => {
      dateAdapter.upsertMany(state, safeArray(payload.dates));
    });
    builder.addCase(fetchBookings.fulfilled, (state, { payload }) => {
      dateAdapter.upsertMany(state, safeArray(payload.dates));
    });
    builder.addCase(fetchRequest.fulfilled, (state, { payload }) => {
      dateAdapter.upsertMany(state, safeArray(payload.dates));
    });
    builder.addCase(fetchRequests.fulfilled, (state, { payload }) => {
      dateAdapter.upsertMany(state, safeArray(payload.dates));
    });
    builder.addCase(fetchEvents.fulfilled, (state, { payload }) => {
      dateAdapter.upsertMany(state, safeArray(payload.dates));
    });
    builder.addCase(createEvent.fulfilled, (state, { payload }) => {
      dateAdapter.upsertMany(state, safeArray(payload.dates));
    });
    builder.addCase(updateEvent.fulfilled, (state, { payload }) => {
      dateAdapter.upsertMany(state, safeArray(payload.dates));
    });
    builder.addCase(deleteEvent.fulfilled, (state, { payload }) => {
      dateAdapter.upsertMany(state, safeArray(payload.dates));
    });
    builder.addCase(createDateSeries.fulfilled, (state, { payload }) => {
      dateAdapter.upsertMany(state, safeArray(payload.dates));
    });
    builder.addCase(updateDateSeries.fulfilled, (state, { payload }) => {
      dateAdapter.upsertMany(state, safeArray(payload.dates));
    });
    builder.addCase(deleteDateSeries.fulfilled, (state, { payload }) => {
      dateAdapter.removeMany(
        state,
        Object.values(safeArray(payload.dates)).map(d =>
          dateAdapter.selectId(d)
        )
      );
      state.isLoading = false;
    });
  }
});

export const dateSelectors = dateAdapter.getSelectors<RootState>(
  state => state.dates
);

export default dateSlice.reducer;
