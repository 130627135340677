import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Skeleton, Table as MuiTable } from '@mui/material';
import { TableBody as MuiTableBody } from '@mui/material';
import { TableCell as MuiTableCell } from '@mui/material';
import { TableContainer as MuiTableContainer } from '@mui/material';
import { TableHead as MuiTableHead } from '@mui/material';
import { Collapse as MuiCollapse } from '@mui/material';
import { TableRow as MuiTableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Button from './Button';
import Dropdown from './Dropdown';
import Icon from './Icon';
import classNames from '../utils/react/classNames';

const useStyles = makeStyles(theme => ({
  tableWrapper: {
    minWidth: '100%',
    width: 'min-content',
    maxWidth: '100%',
    overflowX: 'auto'
  },
  table: {
    width: props => (props.fullWidth ? '100%' : 'auto'),
    fontSize: '0.9em',
    borderSpacing: 0
  },
  thead: {
    color: theme.color.text.main,
    background: theme.color.background.default
  },
  th: {
    color: theme.color.text.main,
    fontWeight: '600',
    padding: '1em .5em',
    border: 'none'
  },
  tr: {
    background: 'transparent',
    transition: 'background-color 100ms linear',
    '&:hover': {
      background: theme.color.background.grey
    },
    '&:last-child td': {
      border: 'none'
    }
  },
  td: {
    color: theme.color.text.main,
    padding: '1em .5em',
    verticalAlign: props => props.verticalAlign
  },
  subContent: {
    '&:hover': {
      background: `${theme.color.background.default} !important`
    }
  },
  tableHead: {
    display: 'flex',
    gap: '0.125em',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '&:hover $dropDownButton': {
      opacity: 1
    }
  },
  tableHeadHidden: {
    position: 'absolute',
    width: 1,
    height: 1,
    overflow: 'hidden'
  },
  headCell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    lineHeight: 'initial'
  },
  sortableHeadCell: {
    '&:hover': {
      color: theme.color.background.primary,
      cursor: 'pointer'
    }
  },
  dropDownButton: {
    opacity: 0
  }
}));

function getHeadCell(column, classes) {
  return (
    <Box
      tabIndex={column.sortFn != null ? 0 : undefined}
      onClick={column.sortFn}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          column.sortFn(e);
        }
      }}
      className={`${classes.headCell} ${
        column.sortFn != null ? classes.sortableHeadCell : ''
      } ${column.labelHidden ? classes.tableHeadHidden : ''}`}
    >
      {column.label}
    </Box>
  );
}

function getBodyCell(row, column) {
  if (!!column.render) {
    return column.render(row);
  }

  if (!!row[column.id]) {
    return row[column.id];
  }

  return null;
}

function getTableHead(columns, headerOptions, classes, order) {
  return (
    <MuiTableHead className={classes.thead}>
      <MuiTableRow className={classes.tr}>
        {columns.map((column, index) => {
          return (
            <MuiTableCell
              width={column.width}
              key={`table-header-column-${index}`}
              className={classes.th}
            >
              <Box className={classes.tableHead}>
                {getHeadCell(column, classes)}
                {column.sortFn &&
                  order.indicator &&
                  column.id === order.column && (
                    <Icon
                      title="Spalte aufsteigend sortiert"
                      name="arrow-down"
                    />
                  )}
                {column.sortFn &&
                  order.indicator &&
                  `${column.id}_desc` === order.column && (
                    <Icon title="Spalte absteigend sortiert" name="arrow-up" />
                  )}
                {column.sortFn &&
                  order.indicator &&
                  ![`${column.id}_desc`, column.id].includes(order.column) && (
                    <Icon name="dot" />
                  )}
                {headerOptions && (
                  <Dropdown
                    // onOpen={() => console.log('onOpen')}
                    // onClose={() => console.log('onClose')}
                    entries={headerOptions}
                  >
                    <Box ml={0.5}>
                      <Button
                        tabIndex={-1}
                        icon
                        size="small"
                        className={classes.dropDownButton}
                      >
                        more
                      </Button>
                    </Box>
                  </Dropdown>
                )}
              </Box>
            </MuiTableCell>
          );
        })}
      </MuiTableRow>
    </MuiTableHead>
  );
}

function getTableBody(rows, columns, classes, loading, rowClassName) {
  const [collapsedRow, setCollapsedRow] = useState();

  return (
    <MuiTableBody
      style={{ visibility: loading ? 'hidden' : 'visible' }}
      className={classes.tbody}
    >
      {rows.map((row, index) => (
        <React.Fragment key={`table-body-row-${index}`}>
          <MuiTableRow
            title={row.title}
            className={classNames([
              classes.tr,
              rowClassName ? rowClassName(row) : null
            ])}
          >
            {row?.subContent !== undefined && (
              <MuiTableCell className={classes.td}>
                {collapsedRow !== undefined && collapsedRow === index ? (
                  <Button
                    // TBD: Intl
                    aria-label="Untergeordnete Tabelle öffnen"
                    icon
                    onClick={() => setCollapsedRow()}
                    tabIndex={0}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setCollapsedRow();
                      }
                    }}
                  >
                    arrow-up
                  </Button>
                ) : (
                  <Button
                    // TBD: Intl
                    aria-label="Untergeordnete Tabelle schließen"
                    icon
                    onClick={() => setCollapsedRow(index)}
                    tabIndex={0}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setCollapsedRow(index);
                      }
                    }}
                  >
                    arrow-right
                  </Button>
                )}
              </MuiTableCell>
            )}
            {columns
              .filter(c => c.id !== 'subContentExpander')
              .map((column, index) => {
                return (
                  <MuiTableCell
                    style={
                      row?.deactivatedTableRow
                        ? {
                            border: loading ? 0 : null,
                            textDecoration: 'line-through'
                          }
                        : { border: loading ? 0 : null }
                    }
                    key={`table-body-row-column-${index}`}
                    className={classes.td}
                  >
                    {getBodyCell(row, column)}
                  </MuiTableCell>
                );
              })}
          </MuiTableRow>
          {row?.subContent !== undefined && (
            <MuiTableRow
              className={classNames([classes.tr, classes.subContent])}
            >
              <MuiTableCell
                colSpan={columns.length}
                style={{ paddingBottom: 0, paddingTop: 0 }}
                className={classes.td}
              >
                <MuiCollapse
                  in={collapsedRow === index}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ margin: 1 }}>{row.subContent}</Box>
                </MuiCollapse>
              </MuiTableCell>
            </MuiTableRow>
          )}
        </React.Fragment>
      ))}
    </MuiTableBody>
  );
}

const Table = React.forwardRef(function Table(props, ref) {
  const classes = useStyles(props);
  // Check if any row has sub content
  const hasSubContent = props.rows?.filter(r => r && r.subContent)?.length > 0;
  // If sub content exist, make sure we add an empty column for expander icon
  const columns = hasSubContent
    ? [
        {
          id: 'subContentExpander',
          // TBD: intl
          label: 'Untergeordnete Tabelle öffen/schließen',
          labelHidden: true
        }
      ].concat(props.columns)
    : props.columns;

  return (
    <MuiTableContainer
      ref={ref}
      className={classNames([classes.tableWrapper, props.className])}
      id={props.id}
    >
      <MuiTable
        className={classes.table}
        aria-label={props.label || 'table'}
        data-testid={props['data-testid']}
      >
        {getTableHead(columns, props.headerOptions, classes, {
          indicator: props.orderIndicator,
          column: props.orderByColumn
        })}
        {props.loading && (
          <MuiTableBody data-testid="loading-indicator">
            {[...Array(props.expectedLength).keys()].map(i => (
              <MuiTableRow key={i}>
                {columns.map((c, j) => (
                  <MuiTableCell key={j}>
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '1rem' }}
                      width={c.width ? (parseFloat(c.width) / 100) * 512 : 48}
                    />
                  </MuiTableCell>
                ))}
              </MuiTableRow>
            ))}
          </MuiTableBody>
        )}
        {getTableBody(
          props.rows,
          columns,
          classes,
          props.loading,
          props.rowClassName
        )}
      </MuiTable>
    </MuiTableContainer>
  );
});

Table.propTypes = {
  id: PropTypes.string,
  verticalAlign: PropTypes.oneOf(['top', 'center', 'bottom']),
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  expectedLength: PropTypes.number,
  headerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.string,
      onClick: PropTypes.func
    })
  ),
  orderIndicator: PropTypes.bool,
  orderByColumn: PropTypes.string,
  'data-testid': PropTypes.string
};

Table.defaultProps = {
  verticalAlign: 'top',
  expectedLength: 10,
  fullWidth: false,
  loading: false,
  headerOptions: undefined,
  orderIndicator: false,
  orderByColumn: undefined
};

export default Table;
